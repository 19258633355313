@tailwind base;
@tailwind components;
@tailwind utilities;

.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 2;
  }
  
  .dropdown-content a {
    color: black;
    padding: 10px 12px;
    text-decoration: none;
    display: block;
    font-size: 16px;
  }
  
  .dropdown-content a:hover {background-color: #ddd;}
  
  .dropdown:hover .dropdown-content {display: block;}
  
  .dropdown:hover {background-color: transparent;}
  
  body {
    font-family: 'Montserrat', sans-serif;
    
  }

  p, li, a { 
    font-family: 'Montserrat', sans-serif;
  }

  h1, h2, h3 {
    font-family: 'Cormorant', serif;
  }
  
  .cormorant {
    font-family: 'Cormorant', serif;
  }

  .monsterrat {
    font-family: 'Montserrat', sans-serif;
  }